var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('form-tak-tertagih', {
    on: {
      "submit": _vm.doBadDebt
    }
  }), _c('b-col', {
    attrs: {
      "md": "10",
      "lg": "8",
      "sm": "12"
    }
  }, [_vm.penjualan && _vm.penjualan.tak_tertagih == 1 ? _c('div', {
    staticClass: "alert alert-danger p-1"
  }, [_c('i', [_vm._v("Penjualan ini telah ditandai menjadi piutang tak tertagih oleh "), _c('strong', [_vm._v("Finance.")])])]) : _vm._e(), _c('b-card', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h4', {}, [_c('strong', [_vm._v(" Detail Transaksi ")])])]), _vm.isFinance && _vm.penjualan && _vm.penjualan.status == 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_vm.penjualan.tak_tertagih == 0 ? _c('a', {
    staticClass: "d-block text-right text-danger",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmBadDebt($event);
      }
    }
  }, [_vm._v("Tandai sebagai piutang tak tertagih?")]) : _c('a', {
    staticClass: "d-block text-right text-primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelBadDebt($event);
      }
    }
  }, [_vm._v("Batalkan sebagai piutang tak tertagih")])]) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9",
      "sm": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No. Nota"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.penjualan ? _vm.penjualan.no_kwitansi : ""))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konsumen"
    }
  }, [_vm.penjualan && _vm.penjualan.konsumen ? _c('router-link', {
    attrs: {
      "to": "/ajuankonsumen/".concat(_vm.penjualan.konsumen ? _vm.penjualan.konsumen.id : '')
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.penjualan.konsumen ? _vm.penjualan.konsumen.nama_toko : _vm.penjualan.id_konsumen))])]) : _c('strong', {
    staticClass: "font-italic"
  }, [_vm._v("Data Konsumen Terhapus")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sales"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.penjualan && _vm.penjualan.sales ? _vm.penjualan.sales.nama_lengkap : ""))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Transaksi"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.penjualan ? _vm.penjualan.tanggal : "-"))])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Transaksi"
    }
  }, [_vm.penjualan && _vm.penjualan.status == 1 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Lunas ")]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Belum Lunas ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Pembayaran"
    }
  }, [_vm.penjualan && _vm.penjualan.bayar == 0 ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Belum Bayar ")]) : _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Sudah Bayar ")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rute Penjualan"
    }
  }, [!_vm.rute ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Rute belum ada")]) : _c('strong', [_vm._v(_vm._s(_vm.rute.nama_rute))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_vm.isSales && _vm.penjualan && _vm.penjualan.status == 0 && _vm.penjualan.tak_tertagih == 0 ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.viewBayar($event);
      }
    }
  }, [_vm._v(" Bayar ")]) : _vm._e(), _c('b-button', {
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printNotaPenjualan($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Nota Penjualan ")], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Informasi Pembayaran "), !_vm.thereIsDebt ? _c('span', [_vm._v("(Lunas)")]) : _vm._e()])]), _c('ul', {
    staticClass: "p-1 list-unstyled w-100"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Total Harga Barang")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.penjualan ? _vm.formatRupiah(_vm.penjualan.total_bayar) : 0))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Pengurangan Saldo")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("-" + _vm._s(_vm.penjualan ? _vm.formatRupiah(_vm.penjualan.saldo) : ""))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Total Pembayaran")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    class: _vm.thereIsDebt ? '' : 'text-success'
  }, [_vm._v(_vm._s(_vm.penjualan ? _vm.formatRupiah(_vm.penjualan.total_pembayaran) : 0))])])]), _vm.thereIsDebt ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Hutang")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.formatRupiah(_vm.debt)))])])]) : _vm._e(), _vm.thereIsChange ? _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Kembalian")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.formatRupiah(_vm.change)))])])]) : _vm._e(), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v("Total Diskon")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v("-" + _vm._s(_vm.penjualan ? _vm.formatRupiah(_vm.penjualan.diskon) : ""))])])])])]), _c('b-card-actions', {
    attrs: {
      "collapsed": "",
      "title": "Rincian Barang (".concat(_vm.penjualan && _vm.penjualan.rincian ? _vm.penjualan.rincian.length : 0, ")"),
      "action-collapse": ""
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "striped": "",
      "hover": "",
      "fields": _vm.barangFields,
      "items": _vm.penjualan ? _vm.penjualan.rincian : [],
      "per-page": 10,
      "current-page": _vm.currentPage
    },
    scopedSlots: _vm._u([{
      key: "cell(barang)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v(" Barang dihapus atau hilang ")])];
      }
    }, {
      key: "cell(qty)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.qty) + " " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : "") + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.harga_jual)) + " ")];
      }
    }, {
      key: "cell(diskon)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(harga_total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(parseInt(item.qty) * parseInt(item.harga_jual) - item.diskon)) + " ")];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": 10,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.penjualan && _vm.penjualan.pembayaran && _vm.penjualan.pembayaran.length > 0 ? _c('b-card-actions', {
    attrs: {
      "title": "History Pembayaran (".concat(_vm.penjualan && _vm.penjualan.pembayaran && _vm.penjualan.pembayaran.length > 0 ? _vm.penjualan.pembayaran.length : '', ")"),
      "collapsed": "",
      "action-collapse": ""
    }
  }, _vm._l(_vm.penjualan.pembayaran, function (payment, i) {
    return _c('ul', {
      key: i,
      staticClass: "border p-1 list-unstyled w-100 mb-1"
    }, [_c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Metode Pembayaran")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.getMetode(payment.metode)))])])]), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Nominal")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.getNominal(payment))))])])]), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Jumlah Bayar")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.penjualan.total_bayar)))])])]), _vm.isTransfer(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Bank Tujuan")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.bank.toUpperCase()))])])]) : _vm._e(), payment.tgl_pembayaran ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v(" " + _vm._s(_vm.isGiro(payment.metode) ? "Tanggal Transaksi" : "Tanggal Pembayaran") + " ")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(_vm.getDate(payment.tgl_pembayaran)))])])]) : _vm._e(), _vm.isGiro(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("No. Giro")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.no_giro))])])]) : _vm._e(), _vm.isGiro(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Tanggal Giro")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.tgl_giro))])])]) : _vm._e(), _vm.isGiro(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Tanggal Bayar")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.tgl_bayar))])])]) : _vm._e(), _vm.isGiro(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("No. Rekening")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.norek))])])]) : _vm._e(), _vm.isGiro(payment.metode) ? _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Pemilik")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.pemilik))])])]) : _vm._e(), _c('li', {
      staticClass: "d-flex justify-content-between mb-1"
    }, [_c('div', {
      staticClass: "details-title"
    }, [_vm._v("Ket.")]), _c('div', {
      staticClass: "detail-amt"
    }, [_c('strong', [_vm._v(_vm._s(payment.keterangan))])])])]);
  }), 0) : _vm._e(), _vm.isSales ? _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Lainnya")])]), _vm.penjualan && _vm.penjualan.bayar == 1 ? _c('ul', {
    staticClass: "p-1 list-unstyled w-100 mb-1"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_c('h5', [_c('strong', [_vm._v("Batalkan Transaksi?")])])]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelTransaction($event);
      }
    }
  }, [_vm._v("Batalkan")])], 1)])]) : _vm._e(), _vm.penjualan && _vm.penjualan.bayar == 0 ? _c('ul', {
    staticClass: "p-1 list-unstyled w-100 mb-1"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_c('h5', [_c('strong', [_vm._v("Hapus Transaksi?")])])]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeTransaction($event);
      }
    }
  }, [_vm._v("Hapus")])], 1)])]) : _vm._e()]) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "cancel-form",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.doCancel($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tulis alasan anda membatalkan transaksi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.cancelForm.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.cancelForm, "keterangan", $$v);
      },
      expression: "cancelForm.keterangan"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }